import React, { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import { addMonths, format } from 'date-fns';
import { membersUpgrade } from "../constants";
import Button from './Button';
import { check } from "../assets";
import { LeftLine, RightLine } from './design/Pricing';
import PayPalButton from './PayPalButton';
import MembershipModal from './MembershipModal'; // Import Modal component
import './Modal.css'

const Membership = () => {
  const { user, updateUserDetails } = useContext(AuthContext);
  const [message, setMessage] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null); // State to store the selected plan
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const handlePayPalSuccess = async (membershipType, expiry) => {
    try {
      if (user) {
        const newDetails = {
          membershipType,
          membershipExpiration: expiry,
        };
        await updateUserDetails(user.uid, newDetails);
        // console.log("Membership updated", newDetails);
        setMessage('Membership upgraded successfully!');
        setSelectedPlan(null); // Reset selected plan
        setIsModalOpen(false); // Close modal on success
        alert("Successfully upgraded to ", membershipType)
      } else {
        console.log("No user is logged in.");
      }
    } catch (err) {
      console.log(err);
      setMessage('Failed to upgrade membership.');
    }
  };

  const fetchCurrentPlan = () => {
    switch (user.membershipType) {
      case 'free':
        return 0;
      case 'basic':
        return 1;
      case 'gold':
        return 2;
      case 'platinum':
        return 3;
      default:
        return 0;
    }
  };

  const calculateExpirationDate = (months) => {
    const currentDate = new Date();
    const expirationDate = addMonths(currentDate, months);
    return format(expirationDate, 'yyyy-MM-dd');
  };

  const handleUpgradeClick = (membershipType, expiry) => {
    setSelectedPlan({ membershipType, expiry });
    setIsModalOpen(true); // Open modal on upgrade click
  };

  return (
    <div style={{ 'textAlign': 'center', 'overflow': 'hidden' }}>
      <div className="relative">
        <div className="max-lg:flex-wrap">
          <div
            key="0"
            className="w-[19rem] max-lg:w-full h-full px-6 bg-n-8 border border-n-6 rounded-[2rem] lg:w-auto even:py-14 odd:py-8 odd:my-4 [&>h4]:first:text-color-2 [&>h4]:even:text-color-1 [&>h4]:last:text-color-3"
          >
            <p className="body-2 mb-3 text-n-1/50">Current Plan</p>
            <div>
              <>
                <div className="memberPlan leading-none font-bold">
                  {user.membershipType ? user.membershipType.toUpperCase() : 'FREE'}
                </div>
              </>
            </div>
            <h4 className="h4 mb-4 sm:text-[2rem] md:text-[2.5rem]">Expiry: {user.membershipExpiration || '0-0-0'}</h4>
          </div>
        </div>
        <LeftLine />
        <RightLine />
      </div>

      <div className="flex gap-[1rem] max-lg:flex-wrap">
        {membersUpgrade.map((item) => (
          fetchCurrentPlan() < item.itemPlan ? (
            <div
              key={item.id}
              className="w-[19rem] max-lg:w-full h-full px-6 bg-n-8 border border-n-6 rounded-[2rem] lg:w-auto even:py-14 odd:py-8 odd:my-4 [&>h4]:first:text-color-2 [&>h4]:even:text-color-1 [&>h4]:last:text-color-3"
            >
              <h4 className="h4 mb-4">{item.title}</h4>
              <p className="body-2 min-h-[4rem] mb-3 text-n-1/50">{item.description}</p>
              <div className="flex items-center h-[5.5rem] mb-6">
                {item.price && (
                  <>
                    <div className="h3">$</div>
                    <div className="text-[5.5rem] leading-none font-bold">{item.price}</div>
                  </>
                )}
              </div>

              <Button
                className="w-full mb-6"
                onClick={() => window.location.href = 'tel:+19056076662'}
                white={!!item.price}
              >
                Call Now to Upgrade!
              </Button>

              <ul>
                {item.features.map((feature, index) => (
                  <li
                    key={index}
                    className="flex items-start py-5 border-t border-n-6"
                  >
                    <img src={check} width={24} height={24} alt="Check" />
                    <p className="body-2 ml-4">{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div key={item.id} className="w-[19rem] max-lg:w-full h-full px-6 bg-n-8 border border-n-6 rounded-[2rem] lg:w-auto even:py-14 odd:py-8 odd:my-4">
              <h4 className="h4 mb-4">You already have all features from {item.title}</h4>
            </div>
          )
        ))}
      </div>

      {selectedPlan && (
        <MembershipModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <PayPalButton
            amount={selectedPlan.membershipType === 'basic' ? 54 : selectedPlan.membershipType === 'gold' ? 113 : 226}
            onSuccess={() => handlePayPalSuccess(selectedPlan.membershipType, selectedPlan.expiry)}
          />
        </MembershipModal>
      )}

      {message && <p>{message}</p>}
    </div>
  );
};

export default Membership;
