import React, { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import HeaderMin from '../components/HeaderMin';
import Footer from '../components/Footer';
import YouTubeStreams from '../components/YouTubeStreams';

const LiveStreams = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [selectedStream, setSelectedStream] = useState(null);

  const streams = [
    { id: 1, videoId: 'PNeA_F5jHi8' },
    { id: 2, videoId: '2pn5xPVxPG4' },
    // { id: 3, videoId: 'L_jWHffIx5E' },
    // { id: 4, videoId: 'M3r2XDceM6A' },
  ];

  const handleStreamSelect = (streamId) => {
    setSelectedStream(streamId === selectedStream ? null : streamId);
  };

  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
      {isAuthenticated ? (
        <div>
          <HeaderMin />
          <div className="App">
            <h1>Live Streams for Tables</h1>
            <div className="stream-buttons">
              {streams.map((stream) => (
                <button
                  key={stream.id}
                  onClick={() => handleStreamSelect(stream.id)}
                  style={{
                    border: '1px solid #000',
                    padding: '10px 20px',
                    margin: '10px',
                    backgroundColor: selectedStream === stream.id ? 'green' : 'white',
                    color: selectedStream === stream.id ? 'white' : 'black',
                    cursor: 'pointer',
                  }}
                >
                  View Table {stream.id}
                </button>
              ))}
            </div>
            {selectedStream && (
              <YouTubeStreams stream={streams.find((stream) => stream.id === selectedStream)} />
            )}
          </div>
          <Footer />
        </div>
      ) : (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '2rem',
          }}
        >
          THIS PAGE IS ONLY ACCESSIBLE FOR BILLIARD LOUNGE STAFF AND ADMIN.
        </div>
      )}
    </div>
  );
};

export default LiveStreams;
